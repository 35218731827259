<template>
  <div>
    <div id="mobymeetButton">
      <a v-b-modal.modal-meet>Agendar Reunión</a>
    </div>
    <b-modal id="modal-meet" size="xl" centered ok-disabled hide-footer>
      <mobysuite-meet-widget
        v-if="projectId"
        customer_id="fortaleza"
        :project_id="projectId"
        color="#6ebf58"
        is_button="false"
        is_virtual="false"
      >
      </mobysuite-meet-widget>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "MobymeetModal",
  props: {
    projectId: {
      type: Number,
    },
  },
};
</script>

<style></style>
